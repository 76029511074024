import React from "react"
import FlexBox from "components/layout/FlexBox"

function DisplayCouldNotLoadMessage() {
  return (
    <FlexBox style={{minHeight: "90vh", lineHeight: 1.2, textAlign: "center"}} centered>
      <FlexBox style={{
        fontSize: 40,
        fontWeight: 300,
        color: "#4a505d",
        margin: 0
      }}>Sorry, the website could not be loaded at this time</FlexBox>
    </FlexBox>
  )
}

export default DisplayCouldNotLoadMessage
