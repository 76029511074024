import {of, merge} from "rxjs"
import {catchError, filter, mergeMap, switchMap, tap} from "rxjs/operators"
import * as Sentry from "@sentry/react"
import {actionFactory, actionTypes, timingPairs} from "api/app/AppReducer"
import {actionTypes as identityActionTypes} from "api/identity/IdentityReducer"
import appApi from "api/app/AppApi"
import identityEpics from "api/identity/IdentityEpics"
import incomeEpics from "api/income/IncomeEpics"
import version from "lib/version"
import uuid from "lib/uuid"

const AppEpics = {
  exchangeToken: (epic$, {apiKey}) => {
    return epic$.pipe(
      filter(({action}) => action.type === actionTypes.exchangeToken),
      filter(({action}) => action.payload?.token && action.payload?.token?.trim()?.length > 0),
      switchMap(({action: {payload}}) =>
        appApi.exchangeToken({payload, apiKey}).pipe(
          mergeMap((res) => {
            return of(actionFactory.exchangeTokenCompleted(res.data))
          }),
          catchError((error) => {
            return of(actionFactory.exchangeTokenFailed({error, payload}))
          }),
        )
      )
    )
  },
  customerStatus: (epic$, {apiKey}) => {
    return epic$.pipe(filter(({action}) => action.type === actionTypes.customerStatus)).pipe(
      switchMap(({action: {payload}}) =>
        appApi.customerStatus({payload, apiKey}).pipe(
          mergeMap((res) => {
            return of(actionFactory.customerStatusCompleted(res.data))
          }),
          catchError((error) => {
            return of(actionFactory.customerStatusFailed({error, payload}))
          }),
        )
      )
    )
  },
  triggerExchangeToken: (epic$) => {
    return epic$.pipe(
      filter(({action}) => action.type === actionTypes.onTokenUpdated),
      filter(({action}) => action.payload !== undefined
        && action.payload.token !== undefined))
      .pipe(
        switchMap(({action}) => {
          return of(actionFactory.exchangeToken(action.payload))
        })
      )
  },
  blockedCameraDetected: (epic$, {apiKey}) => {
    return epic$.pipe(filter(({action}) => action.type === actionTypes.blockedCameraDetected)).pipe(
      switchMap(({action: {payload}}) =>
        appApi.blockedCamera({payload, apiKey}).pipe(
          mergeMap((res) => {
            return of(actionFactory.blockedCameraDetectedCompleted(res.data))
          }),
          catchError((error) => {
            return of(actionFactory.blockedCameraDetectedFailed({error, payload}))
          }),
        )
      )
    )
  },
  captureSentryErrors: (epic$) => {
    const whiteList = [
      actionTypes.onUnexpectedCaptureLiveFaceError,
      actionTypes.noConfigurationProfileFound,
    ]
    return epic$.pipe(
      filter(({action}) => whiteList.includes(action.type)),
      filter(({action}) => action?.payload?.message),
      tap(({action}) => {
        Sentry.captureMessage(action?.payload?.message, "error")
      })
    )
  },
  metricLogging: (epic$, {apiKey}) => {
    const metricWhiteList = [
      actionTypes.onNoTokenFound,
      actionTypes.exchangeTokenCompleted,
      actionTypes.exchangeTokenFailed,
      actionTypes.blockedCameraDetected,
      actionTypes.captureLiveFaceTimedOut,
      actionTypes.captureLiveFaceFailedToLoad,
      actionTypes.onUnexpectedCaptureLiveFaceError,
      actionTypes.noConfigurationProfileFound,
      actionTypes.cameraInitializationTimedOut,
      identityActionTypes.uploadSecureCaptureSelfieCompleted,
      identityActionTypes.uploadSecureCaptureSelfieFailed,
      identityActionTypes.onTrySecureCaptureSelfieAgainClicked
    ]

    const endEvents = timingPairs.map(pair => pair.endAction)
    const timingPairsMap = timingPairs.reduce((acc, pair) => {
      acc[pair.endAction] = pair
      return acc
    }, {})

    const metrics$ = epic$.pipe(
      filter(({action}) => metricWhiteList.includes(action.type)),
      switchMap(({action, currState}) => {
        const {type} = action
        const errorPayload = action?.payload || {}
        const errorCode = errorPayload?.response?.data?.error?.errors?.[0]?.errorCode

        const payload = {
          uploadRequestId: currState?.tokenState?.uploadRequestId || uuid(),
          timestamp: new Date().toISOString(),
          deviceInfo: currState.deviceInfo,
          appInfo: {
            version: version
          },
          errorCode
        }
        payload[type] = true
        return of(payload)
      })
    )

    const timing$ = epic$.pipe(
      filter(({action}) => endEvents.includes(action.type)),
      switchMap(({action, currState}) => {
        const {type} = action
        const {startAction, metricName} = timingPairsMap[type]
        const duration = currState.timingMap[type] - currState.timingMap[startAction]

        const payload = {
          uploadRequestId: currState?.tokenState?.uploadRequestId || uuid(),
          timestamp: new Date().toISOString(),
          [metricName]: duration
        }
        return of(payload)
      })
    )

    return merge(metrics$, timing$).pipe(
      mergeMap(payload =>
        appApi.logUserJourneyEvent({payload, apiKey}).pipe(
          mergeMap((res) => of(actionFactory.logUserJourneyEventCompleted(res.data))),
          catchError((error) => of(actionFactory.logUserJourneyEventFailed({error, payload})))
        )
      )
    )
  }
}

// convert object into an array of functions
export default Object.keys(AppEpics).map(k => AppEpics[k])
  .concat(identityEpics)
  .concat(incomeEpics)
