import React, {createContext, useCallback, useContext} from "react"
import {useStreamReducer} from "hooks/useStreamReducer"
import {appReducer, actionFactory} from "api/app/AppReducer"
import {actionFactory as identityActionFactory} from "api/identity/IdentityReducer"
import {actionFactory as incomeActionFactory} from "api/income/IncomeReducer"
import appEpics from "api/app/AppEpics"
import {generateTheme} from "theme/theme"
import {getDeviceInfo} from 'lib/deviceInfo'

const AppContext = createContext(undefined)
export const useAppContext = () => useContext(AppContext)

function AppContextProvider({children, config}) {
  const {apiKey} = config || {}
  const deviceInfo = getDeviceInfo()

  const [appState, dispatch, ready] = useStreamReducer(
    appReducer,
    {
      exchangeTokenLoading: false,
      customerStatusLoading: false,
      tenantCode: undefined,
      tenantConfig: undefined,
      token: undefined,
      noTokenFound: false,
      tokenState: undefined,
      identityState: {},
      incomeState: {},
      theme: generateTheme(),
      deviceInfo,
      timingMap: {},
    },
    appEpics,
    {apiKey}
  )

  const onTokenUpdated = useCallback((token) => {
    dispatch(actionFactory.onTokenUpdated({token}))
  }, [dispatch])

  const onNoTokenFound = useCallback(() => {
    dispatch(actionFactory.onNoTokenFound())
  }, [dispatch])

  const onTenantConfigUpdated = useCallback((tenantConfig) => {
    dispatch(actionFactory.onTenantConfigUpdated({tenantConfig}))
  }, [dispatch])

  const onLandingPageMounted = useCallback((token) => {
    dispatch(actionFactory.onLandingPageMounted({token}))
  }, [dispatch])

  const onCameraMounted = useCallback(() => {
    dispatch(actionFactory.cameraMounted())
  }, [dispatch])

  const onCameraUnmounted = useCallback(() => {
    dispatch(actionFactory.cameraUnmounted())
  }, [dispatch])

  const onCameraOpened = useCallback(() => {
    dispatch(actionFactory.cameraOpened())
  }, [dispatch])

  const uploadBankStatement = useCallback((payload) => {
    dispatch(incomeActionFactory.uploadBankStatement(payload))
  }, [dispatch])

  const uploadPayslip = useCallback((payload) => {
    dispatch(incomeActionFactory.uploadPayslip(payload))
  }, [dispatch])

  const uploadSecureCaptureSelfie = useCallback((payload) => {
    dispatch(identityActionFactory.uploadSecureCaptureSelfie(payload))
  }, [dispatch])

  const onTrySecureCaptureSelfieAgainClicked = useCallback(() => {
    dispatch(identityActionFactory.onTrySecureCaptureSelfieAgainClicked())
  }, [dispatch])

  const onFaceDetectionTextChanged = useCallback((payload) => {
    dispatch(identityActionFactory.onFaceDetectionTextChanged({faceDetectionCodes: payload}))
  }, [dispatch])

  const onBlockedCameraDetected = useCallback(() => {
    dispatch(actionFactory.blockedCameraDetected({token: appState.token}))
  }, [dispatch, appState.token])

  const onCaptureLiveFaceFailedToLoad = useCallback(() => {
    dispatch(actionFactory.captureLiveFaceFailedToLoad())
  }, [dispatch])

  const onCaptureLiveFaceTimedOut = useCallback(() => {
    dispatch(actionFactory.captureLiveFaceTimedOut())
  }, [dispatch])

  const onUnexpectedCaptureLiveFaceError = useCallback((payload) => {
    dispatch(actionFactory.onUnexpectedCaptureLiveFaceError(payload))
  }, [dispatch])

  const onNoConfigurationProfileFound = useCallback((payload) => {
    dispatch(actionFactory.noConfigurationProfileFound(payload))
  }, [dispatch])

  const onCameraInitializationTimedOut = useCallback(() => {
    dispatch(actionFactory.cameraInitializationTimedOut())
  }, [dispatch])

  const onResetCameraInitialization = useCallback(() => {
    dispatch(actionFactory.resetCameraInitialization())
  }, [dispatch])

  return (
    <AppContext.Provider value={{
      appState: {...appState}, onTokenUpdated, onNoTokenFound, onLandingPageMounted,
      uploadSecureCaptureSelfie, onTrySecureCaptureSelfieAgainClicked, onFaceDetectionTextChanged,
      uploadBankStatement, uploadPayslip, onTenantConfigUpdated, onBlockedCameraDetected, onCaptureLiveFaceFailedToLoad,
      onCaptureLiveFaceTimedOut, onUnexpectedCaptureLiveFaceError, onNoConfigurationProfileFound,
      onCameraInitializationTimedOut, onCameraMounted, onCameraUnmounted, onCameraOpened, onResetCameraInitialization
    }}>
      {ready ? children : null}
    </AppContext.Provider>
  )
}

export default AppContextProvider
