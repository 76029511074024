import produce from "immer"
import {identityReducer} from "api/identity/IdentityReducer"
import {incomeReducer} from "api/income/IncomeReducer"
import {generateTheme} from "theme/theme"

export const actionTypes = {
  onLandingPageMounted: "onLandingPageMounted",
  onTokenUpdated: "onTokenUpdated",
  onTenantConfigUpdated: "onTenantConfigUpdated",
  onNoTokenFound: "onNoTokenFound",
  exchangeToken: "exchangeToken",
  exchangeTokenCompleted: "exchangeTokenCompleted",
  exchangeTokenFailed: "exchangeTokenFailed",
  cameraMounted: "cameraMounted",
  cameraOpened: "cameraOpened",
  cameraUnmounted: "cameraUnmounted",
  customerStatus: "customerStatus",
  customerStatusCompleted: "customerStatusCompleted",
  customerStatusFailed: "customerStatusFailed",
  blockedCameraDetected: "blockedCameraDetected",
  blockedCameraDetectedFailed: "blockedCameraDetectedFailed",
  blockedCameraDetectedCompleted: "blockedCameraDetectedCompleted",
  captureLiveFaceFailedToLoad: "captureLiveFaceFailedToLoad",
  logUserJourneyEvent: 'logUserJourneyEvent',
  logUserJourneyEventCompleted: 'logUserJourneyEventCompleted',
  logUserJourneyEventFailed: 'logUserJourneyEventFailed',
  captureLiveFaceTimedOut: "captureLiveFaceTimedOut",
  onUnexpectedCaptureLiveFaceError: "onUnexpectedCaptureLiveFaceError",
  noConfigurationProfileFound: "noConfigurationProfileFound",
  cameraInitializationTimedOut: "cameraInitializationTimedOut",
  resetCameraInitialization: "resetCameraInitialization",
}

export const actionFactory = {
  onLandingPageMounted: payload => ({type: actionTypes.onLandingPageMounted, payload}),
  onTokenUpdated: payload => ({type: actionTypes.onTokenUpdated, payload}),
  onNoTokenFound: () => ({type: actionTypes.onNoTokenFound}),
  onTenantConfigUpdated: payload => ({type: actionTypes.onTenantConfigUpdated, payload}),
  exchangeToken: payload => ({type: actionTypes.exchangeToken, payload}),
  exchangeTokenCompleted: payload => ({type: actionTypes.exchangeTokenCompleted, payload}),
  exchangeTokenFailed: payload => ({type: actionTypes.exchangeTokenFailed, payload}),
  cameraMounted: () => ({type: actionTypes.cameraMounted}),
  cameraUnmounted: () => ({type: actionTypes.cameraUnmounted}),
  cameraOpened: () => ({type: actionTypes.cameraOpened}),
  customerStatus: payload => ({type: actionTypes.customerStatus, payload}),
  customerStatusCompleted: payload => ({type: actionTypes.customerStatusCompleted, payload}),
  customerStatusFailed: payload => ({type: actionTypes.customerStatusFailed, payload}),
  blockedCameraDetected: payload => ({type: actionTypes.blockedCameraDetected, payload}),
  blockedCameraDetectedFailed: payload => ({type: actionTypes.blockedCameraDetectedFailed, payload}),
  blockedCameraDetectedCompleted: payload => ({type: actionTypes.blockedCameraDetectedCompleted, payload}),
  captureLiveFaceFailedToLoad: () => ({type: actionTypes.captureLiveFaceFailedToLoad}),
  logUserJourneyEvent: payload => ({type: actionTypes.logUserJourneyEvent, payload}),
  logUserJourneyEventCompleted: payload => ({type: actionTypes.logUserJourneyEventCompleted, payload}),
  logUserJourneyEventFailed: payload => ({type: actionTypes.logUserJourneyEventFailed, payload}),
  captureLiveFaceTimedOut: () => ({type: actionTypes.captureLiveFaceTimedOut}),
  onUnexpectedCaptureLiveFaceError: (payload) => ({type: actionTypes.onUnexpectedCaptureLiveFaceError, payload}),
  noConfigurationProfileFound: (payload) => ({type: actionTypes.noConfigurationProfileFound, payload}),
  cameraInitializationTimedOut: () => ({type: actionTypes.cameraInitializationTimedOut}),
  resetCameraInitialization: () => ({type: actionTypes.resetCameraInitialization}),
}

// Define pairs of events to measure time between them
export const timingPairs = [
  {
    startAction: actionTypes.cameraMounted,
    endAction: actionTypes.cameraOpened,
    metricName: 'camera_mount_duration_milliseconds'
  }
]

const reducer = (state, action) => produce(state, draft => {
  const {type, payload} = action
  console.log("Reducing:", type)

  switch (type) {
    case actionTypes.onTokenUpdated:
      draft.token = payload.token
      if (payload.token !== undefined) {
        draft.noTokenFound = false
      }
      break

    case actionTypes.onNoTokenFound:
      draft.noTokenFound = true
      break

    case actionTypes.onTenantConfigUpdated:
      draft.tenantConfig = payload.tenantConfig
      draft.theme = generateTheme(payload.tenantConfig.pallet)
      break

    case actionTypes.exchangeToken:
      draft.exchangeTokenLoading = true
      break

    case actionTypes.exchangeTokenCompleted:
      draft.exchangeTokenLoading = false
      draft.tokenState = payload
      draft.exchangeTokenFailed = false
      break

    case actionTypes.exchangeTokenFailed:
      draft.exchangeTokenLoading = false
      draft.exchangeTokenFailed = true
      break

    case actionTypes.customerStatus:
      draft.customerStatusLoading = true
      draft.customerStatusFailed = false
      break

    case actionTypes.customerStatusCompleted:
      draft.customerStatusLoading = false
      draft.customerStatus = payload
      draft.customerStatusFailed = false
      break

    case actionTypes.customerStatusFailed:
      draft.customerStatusLoading = false
      draft.customerStatusFailed = true
      break

    case actionTypes.cameraInitializationTimedOut:
      draft.cameraInitializationTimedOut=true
      break

    case actionTypes.resetCameraInitialization:
      draft.cameraInitializationTimedOut=false
      break

    case actionTypes.cameraMounted:
      draft.cameraMounted = true
      draft.timingMap[actionTypes.cameraMounted] = new Date().getTime()
      break

    case actionTypes.cameraUnmounted:
      draft.cameraMounted = false
      break

    case actionTypes.cameraOpened:
      draft.timingMap[actionTypes.cameraOpened] = new Date().getTime()
      break

    default:
      // do nothing
  }
})

export const appReducer = (state, action) => reducer(identityReducer(incomeReducer(state, action), action), action)
